import saudicupApi from "@/api/saudicupApi";
import authHeader from "@/services/saudi-cup/auth/auth-header";
class GroupService {
    get_flight_group_id(group_id) {
        return saudicupApi.get(
            `/api/airline/get-flight-group-by-url/${group_id}`, { headers: authHeader() }
        ).then((response) => {
            return response;
        });
    }
}
export default new GroupService();