<template>
    <section id="booking_help" class="section_padding">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="section_heading_center">
                        <h2>{{ $t('Departure Flight') }}</h2>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label class="primary-color bold">{{ $t('From') }} *</label>
                        <model-list-select :list="options" v-model="request.departure_from" :placeholder="$t('select2')"
                            option-value="code" :custom-text="codeAndNameAndDesc">
                        </model-list-select>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label class="primary-color bold">{{ $t('To') }} *</label>
                        <model-list-select :list="options" v-model="request.departure_to" :placeholder="$t('select2')"
                            option-value="code" :custom-text="codeAndNameAndDesc" isDisabled="true">
                        </model-list-select>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label class="primary-color bold">{{ $t('Flight date') }} *</label>
                        <VueDatePicker v-model="request.departure_date" :enable-time-picker="false"
                            :min-date="departure_min_date" :start-date="departure_min_date"
                            :max-date="departure_max_date" @internal-model-change="handleInternal"
                            :placeholder="$t('select2')" />
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label class="primary-color bold">{{ $t('Flight degree') }} *</label>
                        <select v-model="request.departure_booking_degree" class="form-control">
                            <option value="">{{ $t('select2') }}</option>
                            <option v-for="item in allowedDegree" :value="item">{{ $t(item) }}</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-12 mt-4 mb-3">
                    <h4 class="primary-color bold">{{ $t('Favorite time to flight') }}</h4>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label class="primary-color bold">{{ $t('First option') }} *</label>
                        <select class="form-control" v-model="request.prefered_departure_times[0]"
                            @change="prefered_departure_change(0)">
                            <option value="">{{ $t('select2') }}</option>
                            <option :value="'00:00' + $t('am') + '- 06:00' + $t('am')">00:00 {{ $t('am') }} - 06:00 {{
                                $t('am') }}
                            </option>
                            <option :value="'06:00' + $t('am') + '- 12:00' + $t('pm')">06:00 {{ $t('am') }} - 12:00 {{
                                $t('pm') }}
                            </option>
                            <option :value="'12:00' + $t('pm') + '- 18:00' + $t('pm')">12:00 {{ $t('pm') }} - 18:00 {{
                                $t('pm') }}
                            </option>
                            <option :value="'18:00' + $t('pm') + '- 00:00' + $t('am')">18:00 {{ $t('pm') }} - 00:00 {{
                                $t('am') }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label class="primary-color bold">{{ $t('Second option') }} *</label>
                        <select class="form-control" v-model="request.prefered_departure_times[1]"
                            @change="prefered_departure_change(1)">
                            <option value="">{{ $t('select2') }}</option>
                            <option :value="'00:00' + $t('am') + '- 06:00' + $t('am')">00:00 {{ $t('am') }} - 06:00 {{
                                $t('am') }}
                            </option>
                            <option :value="'06:00' + $t('am') + '- 12:00' + $t('pm')">06:00 {{ $t('am') }} - 12:00 {{
                                $t('pm') }}
                            </option>
                            <option :value="'12:00' + $t('pm') + '- 18:00' + $t('pm')">12:00 {{ $t('pm') }} - 18:00 {{
                                $t('pm') }}
                            </option>
                            <option :value="'18:00' + $t('pm') + '- 00:00' + $t('am')">18:00 {{ $t('pm') }} - 00:00 {{
                                $t('am') }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label class="primary-color bold">{{ $t('Third option') }} *</label>
                        <select class="form-control" v-model="request.prefered_departure_times[2]"
                            @change="prefered_departure_change(2)">
                            <option value="">{{ $t('select2') }}</option>
                            <option :value="'00:00' + $t('am') + '- 06:00' + $t('am')">00:00 {{ $t('am') }} - 06:00 {{
                                $t('am') }}
                            </option>
                            <option :value="'06:00' + $t('am') + '- 12:00' + $t('pm')">06:00 {{ $t('am') }} - 12:00 {{
                                $t('pm') }}
                            </option>
                            <option :value="'12:00' + $t('pm') + '- 18:00' + $t('pm')">12:00 {{ $t('pm') }} - 18:00 {{
                                $t('pm') }}
                            </option>
                            <option :value="'18:00' + $t('pm') + '- 00:00' + $t('am')">18:00 {{ $t('pm') }} - 00:00 {{
                                $t('am') }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label class="primary-color bold">{{ $t('Forth option') }} *</label>
                        <select class="form-control" v-model="request.prefered_departure_times[3]"
                            @change="prefered_departure_change(3)">
                            <option value="">{{ $t('select2') }}</option>
                            <option :value="'00:00' + $t('am') + '- 06:00' + $t('am')">00:00 {{ $t('am') }} - 06:00 {{
                                $t('am') }}
                            </option>
                            <option :value="'06:00' + $t('am') + '- 12:00' + $t('pm')">06:00 {{ $t('am') }} - 12:00 {{
                                $t('pm') }}
                            </option>
                            <option :value="'12:00' + $t('pm') + '- 18:00' + $t('pm')">12:00 {{ $t('pm') }} - 18:00 {{
                                $t('pm') }}
                            </option>
                            <option :value="'18:00' + $t('pm') + '- 00:00' + $t('am')">18:00 {{ $t('pm') }} - 00:00 {{
                                $t('am') }}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="row mt20">
                <div class="col-md-12 mt-3">
                    <div class="section_heading_center pull-left">
                        <h2>{{ $t('Return flight') }}</h2>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label class="primary-color bold">{{ $t('From') }} *</label>
                        <model-list-select :list="options" v-model="request.return_from" :placeholder="$t('select2')"
                            option-value="code" :custom-text="codeAndNameAndDesc" isDisabled="true">
                        </model-list-select>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label class="primary-color bold">{{ $t('To') }} *</label>
                        <model-list-select :list="options" v-model="request.return_to" :placeholder="$t('select2')"
                            option-value="code" :custom-text="codeAndNameAndDesc">
                        </model-list-select>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label class="primary-color bold">{{ $t('Flight date') }} *</label>
                        <VueDatePicker v-model="request.return_date" :enable-time-picker="false"
                            :min-date="arrival_min_date" :start-date="arrival_min_date" :max-date="arrival_max_date"
                            @internal-model-change="arrivalHandleInternal" :placeholder="$t('select2')" />
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label class="primary-color bold">{{ $t('Flight degree') }} *</label>
                        <select v-model="request.return_booking_degree" class="form-control">
                            <option value="">{{ $t('select2') }}</option>
                            <option v-for=" item in allowedDegree " :value="item">{{ $t(item) }}</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-12 mt-4 mb-3">
                    <h4 class="primary-color bold">{{ $t('Favorite time to flight') }}</h4>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label class="primary-color bold">{{ $t('First option') }} *</label>
                        <select class="form-control" v-model="request.prefered_return_times[0]"
                            @change="prefered_return_change(0)">
                            <option value="">{{ $t('select2') }}</option>
                            <option :value="'00:00' + $t('am') + '- 06:00' + $t('am')">00:00 {{ $t('am') }} - 06:00 {{
                                $t('am') }}
                            </option>
                            <option :value="'06:00' + $t('am') + '- 12:00' + $t('pm')">06:00 {{ $t('am') }} - 12:00 {{
                                $t('pm') }}
                            </option>
                            <option :value="'12:00' + $t('pm') + '- 18:00' + $t('pm')">12:00 {{ $t('pm') }} - 18:00 {{
                                $t('pm') }}
                            </option>
                            <option :value="'18:00' + $t('pm') + '- 00:00' + $t('am')">18:00 {{ $t('pm') }} - 00:00 {{
                                $t('am') }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label class="primary-color bold">{{ $t('Second option') }} *</label>
                        <select class="form-control" v-model="request.prefered_return_times[1]"
                            @change="prefered_return_change(1)">
                            <option value="">{{ $t('select2') }}</option>
                            <option :value="'00:00' + $t('am') + '- 06:00' + $t('am')">00:00 {{ $t('am') }} - 06:00 {{
                                $t('am') }}
                            </option>
                            <option :value="'06:00' + $t('am') + '- 12:00' + $t('pm')">06:00 {{ $t('am') }} - 12:00 {{
                                $t('pm') }}
                            </option>
                            <option :value="'12:00' + $t('pm') + '- 18:00' + $t('pm')">12:00 {{ $t('pm') }} - 18:00 {{
                                $t('pm') }}
                            </option>
                            <option :value="'18:00' + $t('pm') + '- 00:00' + $t('am')">18:00 {{ $t('pm') }} - 00:00 {{
                                $t('am') }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label class="primary-color bold">{{ $t('Third option') }} *</label>
                        <select class="form-control" v-model="request.prefered_return_times[2]"
                            @change="prefered_return_change(2)">
                            <option value="">{{ $t('select2') }}</option>
                            <option :value="'00:00' + $t('am') + '- 06:00' + $t('am')">00:00 {{ $t('am') }} - 06:00 {{
                                $t('am') }}
                            </option>
                            <option :value="'06:00' + $t('am') + '- 12:00' + $t('pm')">06:00 {{ $t('am') }} - 12:00 {{
                                $t('pm') }}
                            </option>
                            <option :value="'12:00' + $t('pm') + '- 18:00' + $t('pm')">12:00 {{ $t('pm') }} - 18:00 {{
                                $t('pm') }}
                            </option>
                            <option :value="'18:00' + $t('pm') + '- 00:00' + $t('am')">18:00 {{ $t('pm') }} - 00:00 {{
                                $t('am') }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label class="primary-color bold">{{ $t('Forth option') }} *</label>
                        <select class="form-control" v-model="request.prefered_return_times[3]"
                            @change="prefered_return_change(3)">
                            <option value="">{{ $t('select2') }}</option>
                            <option :value="'00:00' + $t('am') + '- 06:00' + $t('am')">00:00 {{ $t('am') }} - 06:00 {{
                                $t('am') }}
                            </option>
                            <option :value="'06:00' + $t('am') + '- 12:00' + $t('pm')">06:00 {{ $t('am') }} - 12:00 {{
                                $t('pm') }}
                            </option>
                            <option :value="'12:00' + $t('pm') + '- 18:00' + $t('pm')">12:00 {{ $t('pm') }} - 18:00 {{
                                $t('pm') }}
                            </option>
                            <option :value="'18:00' + $t('pm') + '- 00:00' + $t('am')">18:00 {{ $t('pm') }} - 00:00 {{
                                $t('am') }}
                            </option>
                        </select>
                    </div>
                </div>
                <div v-if="!is_sending" class="col-md-12 text-center mt-4">
                    <button type="button" class="btn btn_theme btn_md btn-search-flight-next" @click="send">{{
                        $t('Send the request')
                    }}</button>
                    <p class="text-center text-danger mt-4">
                        *{{ $t('help-note') }}
                    </p>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
var that;
import $ from 'jquery';
import jsonDataAr from '@/assets/file/ar-airports.json';
import jsonDataEn from '@/assets/file/en-airports.json';
import { ModelListSelect } from 'vue-search-select';
import "vue-search-select/dist/VueSearchSelect.css";
import { toast } from 'vue3-toastify';
export default {
    name: 'BookingHelpArea',
    components: { ModelListSelect },
    data: () => ({
        options: [],
        item: {},
        request: {
            departure_from: '',
            departure_to: 'RUH',
            departure_date: null,
            departure_booking_degree: '',
            return_from: 'RUH',
            return_to: '',
            return_date: null,
            return_booking_degree: '',
            prefered_departure_times: [],
            prefered_return_times: [],
            guest_id: null
        },
        max_free_period_between_arrival_departure: 0,
        departure_time_condition: 0,
        arrival_time_condition: 0,
        arrival_start_date: '',
        arrival_end_date: '',
        departure_start_date: '',
        departure_end_date: '',
        allowedDegree: [],
        departure_min_date: new Date(),
        departure_max_date: new Date(Date.now() + 365 * 24 * 60 * 60 * 1000),
        arrival_min_date: new Date(),
        arrival_max_date: new Date(Date.now() + 365 * 24 * 60 * 60 * 1000),
        times: [],
        is_sending: false
    }),
    methods: {
        getAllIndices(arr, value, index) {
            for (let i = 0; i < 4; i++) {
                if (value == arr[i] && index != i) {
                    return i;
                }
            }
        },
        getArrayDifference(array1, array2) {
            const combinedArray = [...new Set([...array1, ...array2])];
            const differenceArray = combinedArray.filter(item => !array1.includes(item) || !array2.includes(item));
            return differenceArray[0];
        },
        prefered_return_change(index) {
            if (this.request.prefered_return_times[index] == '') {
                return;
            }
            var prefered_return_times = this.request.prefered_return_times;
            var times = Object.assign([], this.times);
            var ind = times.indexOf(this.request.prefered_return_times[index]);
            times.splice(ind, 1);
            if (prefered_return_times.length != 4) {
                for (let i = 0; i < 4; i++) {
                    if (index != i) {
                        var ind = times.indexOf(times[0]);
                        this.request.prefered_return_times[i] = times[0];
                        times.splice(ind, 1);
                    }
                }
            } else {
                var ind = this.getAllIndices(prefered_return_times, this.request.prefered_return_times[index], index);
                this.request.prefered_return_times[ind] = this.getArrayDifference(this.times, this.request.prefered_return_times);
            }

        },
        prefered_departure_change(index) {
            if (this.request.prefered_departure_times[index] == '') {
                return;
            }
            var prefered_departure_times = this.request.prefered_departure_times;
            var times = Object.assign([], this.times);
            var ind = times.indexOf(this.request.prefered_departure_times[index]);
            times.splice(ind, 1);
            if (prefered_departure_times.length != 4) {
                for (let i = 0; i < 4; i++) {
                    if (index != i) {
                        var ind = times.indexOf(times[0]);
                        this.request.prefered_departure_times[i] = times[0];
                        times.splice(ind, 1);
                    }
                }
            } else {
                var ind = this.getAllIndices(prefered_departure_times, this.request.prefered_departure_times[index], index);
                this.request.prefered_departure_times[ind] = this.getArrayDifference(this.times, this.request.prefered_departure_times);
            }
        },
        codeAndNameAndDesc(item) {
            return `${item.name} ( ${item.code} ) - ${item.country} / ${item.city}`
        },
        format_date(inputDate) {
            var parts = inputDate.split('/');
            var day = parts[0];
            var month = parts[1];
            var year = parts[2];
            return year + '-' + month + '-' + day;
        },
        handleInternal(date) {
            const dateStr1 = date;
            const dateStr2 = this.request.departure_date;
            const date1 = new Date(dateStr1);
            const date2 = new Date(dateStr2);
            if (date1 < date2 || date1 > date2) {
                setTimeout(() => {
                    $(document).find('.dp__action_select').click();
                }, 100);
            }
        },
        arrivalHandleInternal(date) {
            const dateStr1 = date;
            const dateStr2 = this.request.return_date;
            const date1 = new Date(dateStr1);
            const date2 = new Date(dateStr2);
            if (date1 < date2 || date1 > date2) {
                setTimeout(() => {
                    $(document).find('.dp__action_select').click();
                }, 100);
            }
        },
        validateRequest(request) {
            for (const key in this.request) {
                if (this.request[key] === '' || this.request[key] === null || (Array.isArray(this.request[key]) && this.request[key].length === 0)) {
                    return false;
                }
            }
            if (this.request.prefered_departure_times.length !== 4 || this.request.prefered_return_times.length !== 4) {
                return false;
            }
            return true;
        },
        dateDiffInDays(date1, date2) {
            const utcDate1 = Date.UTC(date1.getFullYear(), date1.getMonth(), date1.getDate());
            const utcDate2 = Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate());
            const timeDiff = utcDate2 - utcDate1;
            const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
            return daysDiff;
        },
        send() {
            var is_valid = this.validateRequest(this.request);
            if (!is_valid) {
                toast.error(this.$t('All fields are required'), {
                    autoClose: 3500,
                    position: toast.POSITION.TOP_CENTER,
                    closeButton: false
                });
            } else {
                const differenceInDays = this.dateDiffInDays(new Date(this.request.departure_date), new Date(this.request.return_date));
                if (differenceInDays > this.max_free_period_between_arrival_departure) {
                    toast.error(this.$t('The maximum period between the date of arrival and departure must not exceed') + this.max_free_period_between_arrival_departure + ' ' + this.$t('day'), {
                        autoClose: 3500,
                        position: toast.POSITION.TOP_CENTER,
                        closeButton: false
                    });
                } else {
                    $('.pre-loader').show();
                    this.is_sending = true;
                    var redirect_url = localStorage.getItem("redirect_url");
                    var url = `${this.$saudicupAppUrl}/api/airline/need-help-flight`;
                    this.request.departure_date = new Date(this.request.departure_date).toLocaleDateString('sv-SE');
                    this.request.return_date = new Date(this.request.return_date).toLocaleDateString('sv-SE');
                    this.request.prefered_return_times = JSON.stringify(this.request.prefered_return_times);
                    this.request.prefered_departure_times = JSON.stringify(this.request.prefered_departure_times);
                    this.axios.post(url, this.request, {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem('user_token')}`
                        }
                    }).then((response) => {
                        $('.pre-loader').hide();
                        toast.success(this.$t('The request has been sent successfully'), {
                            autoClose: 3500,
                            position: toast.POSITION.TOP_CENTER,
                            closeButton: false
                        });
                        setTimeout(() => {
                            localStorage.clear();
                            window.location = redirect_url;
                        }, 2000);
                    }).catch(error => {
                        this.is_sending = false;
                        $('.pre-loader').hide();
                        toast.error(this.$t('An error occurred, try again'), {
                            autoClose: 3500,
                            position: toast.POSITION.TOP_CENTER,
                            closeButton: false
                        });
                    });
                }
            }
        }
    },
    mounted() {
        const guest_data = JSON.parse(localStorage.getItem('guest_data'));
        const allowed_to_choose_need_help_option = localStorage.getItem('allowed_to_choose_need_help_option');
        if (guest_data && guest_data.guest.need_flight_helping == null && allowed_to_choose_need_help_option == 1) {
            this.request.guest_id = guest_data.guest.id;
            // guest_data.companions.forEach(element => {
            //     this.request.guest_id.push(element.id);
            // });
            this.times = [
                "00:00ص- 06:00ص",
                "06:00ص- 12:00م",
                "12:00م- 18:00م",
                "18:00م- 00:00ص"
            ];

            this.options = jsonDataAr;
            if (this.$i18n.locale === 'en') {
                this.options = jsonDataEn;
                this.times = [
                    "00:00am- 06:00am",
                    "06:00am- 12:00pm",
                    "12:00pm- 18:00pm",
                    "18:00pm- 00:00am"
                ];
            }
            const allowedDegreeString = localStorage.getItem('allowed_degree');

            this.allowedDegree = JSON.parse(allowedDegreeString);

            this.max_free_period_between_arrival_departure = localStorage.getItem('max_free_period_between_arrival_departure');

            this.departure_start_date = localStorage.getItem('departure_start_date');
            this.departure_end_date = localStorage.getItem('departure_end_date');


            this.arrival_min_date = this.format_date(this.departure_start_date);
            this.arrival_max_date = this.format_date(this.departure_end_date);


            this.arrival_start_date = localStorage.getItem('arrival_start_date');
            this.arrival_end_date = localStorage.getItem('arrival_end_date');

            this.departure_min_date = this.format_date(this.arrival_start_date);
            this.departure_max_date = this.format_date(this.arrival_end_date);

        } else {
            this.$router.replace(`/error`);
        }
    }
}
</script>
<style lang="scss" scoped>
.section_padding {
    padding: 50px 0;
}
label{
    font-size: 14px;
    margin-bottom: 8px;
}
.form-group{
    margin-bottom: 1rem;
}

.rtl{
    .ui.dropdown{
    text-align: right;
}
}
</style>