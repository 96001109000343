import saudicupApi from "@/api/saudicupApi";
import authHeader from "@/services/saudi-cup/auth/auth-header";
class ChatService {
    get_chat_messages() {
        return saudicupApi.get(
            '/api/guest/messages', { headers: authHeader() }
        ).then((response) => {
            return response;
        });
    }
    send_message(body) {
        return saudicupApi.post(
            '/api/chats/send-message', body, { headers: authHeader() }
        ).then((response) => {
            return response;
        });
    }
}
export default new ChatService();