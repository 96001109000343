import Api from "@/api/jskyApi";
const END_POINT = "api/saudicup";
class FlightsService {
    get_flight_offers(params) {
        return Api.get(`${END_POINT}/result`, { params })
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return error.response.data;
            });
    }
}
export default new FlightsService();