import saudicupApi from "@/api/saudicupApi";
import authHeader from "@/services/saudi-cup/auth/auth-header";
const END_POINT = "api";
class GuestService {
    guest_by_encrypted_id(guest_id) {
        return saudicupApi.get(
            `${END_POINT}/current-guest-encrypted/${guest_id}`, { headers: authHeader() }
        ).then((response) => {
            return response;
        });
    }
}
export default new GuestService();